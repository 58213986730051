@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Inter var;
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
    font-named-instance: 'Regular';
    src: url(./assets/fonts/Inter-roman.var.woff2?v=3.18) format('woff2');
  }

  @font-face {
    font-family: Inter var;
    font-weight: 100 900;
    font-display: swap;
    font-style: italic;
    font-named-instance: 'Italic';
    src: url(./assets/fonts/Inter-italic.var.woff2?v=3.18) format('woff2');
  }

  .font-sans {
    font-feature-settings: 'cv02', 'cv03', 'cv04', 'cv11';
  }

  [type='checkbox']:checked {
    background-image: url(./assets/svg/check.svg);
  }

  [type='checkbox']:checked,
  [type='radio']:checked {
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }

  /* Chrome, Safari, Edge, Opera */
  [type='range']::-webkit-slider-thumb {
    @apply h-4 w-4;
    -webkit-appearance: none;
    appearance: none;
    cursor: ew-resize;
    background: #229fa8;
    box-shadow: -405px 0 0 400px #a4d5d5;
    border-radius: 50%;
  }

  /* Firefox */
  [type='range']::-moz-range-thumb {
    border: none;
    @apply h-4 w-4;
    cursor: ew-resize;
    border-radius: 50%;
    background: #229fa8;
  }

  /* Firefox */
  [type='range']::-moz-range-progress {
    background: #a4d5d5;
    border: none;
    @apply h-4;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .footer {
    background: url(./assets/img/footer-bg.png) no-repeat center bottom;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }

  .footer-text {
    font-size: 0.65rem;
  }
}
